import React from "react";
import bmclogo from "../images/bmclogo.svg";

function Buymeacoffee() {
  return (
    <a 
    className="fixed bottom-8 right-6 w-12 h-12" 
    href='https://www.buymeacoffee.com/montessoriicu' 
    target='_blank' 
    rel="noreferrer">
      <img 
        className="object-fit rounded-md p-2 ring-4 ring-gray-200 bg-white hover:bg-gray-100" 
        src={bmclogo} 
        alt='Buy me a coffee' />
    </a>
  );
}

export default Buymeacoffee;
