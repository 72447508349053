import React from 'react';
import PropTypes from "prop-types";
import { Page, Text, View, Image, Canvas } from '@react-pdf/renderer';

class MyPageJa extends React.Component {

  render() {
    let radius = 0;
    if (this.props.params.isRectangle.value === "true") {
      radius = 0;
    } else {
      radius = 10;
    } 

    let lineW = 10;
    if (this.props.params.isRectangle.value === "true") {
      lineW = 10;
    } else {
      lineW = 4;
    } 

    let lineW2 = 0;
    if (this.props.params.isRectangle.value === "true") {
      lineW2 = 0;
    } else {
      lineW2 = 2;
    } 

    let lineW6 = 0;
    if (this.props.params.isRectangle.value === "true") {
      lineW6 = 0;
    } else {
      lineW6 = 6;
    } 

    let lineWithOut = 3;
    if (this.props.params.isRectangle.value === "true") {
      lineWithOut = 3;
    } else {
      lineWithOut = 1;
    } 

    let strokeColor = "white";
    if (this.props.params.isRectangle.value === "true") {
      strokeColor = "white";
    } else {
      strokeColor = "black";
    } 
    if (this.props.params.selected2.color === "#FFFFFF"){
      strokeColor = "black";
    }

    let imgStyle1 = this.props.styles.img;
    if (this.props.params.imageOrientation === 6) {
      let imgStyleTmp = Object.assign({}, imgStyle1)
      imgStyleTmp['transform'] = 'rotate(90deg)'
      imgStyle1 = imgStyleTmp
    }
    else if (this.props.params.imageOrientation === 8) {
      let imgStyleTmp = Object.assign({}, imgStyle1)
      imgStyleTmp['transform'] = 'rotate(-90deg)'
      imgStyle1 = imgStyleTmp
    }
    else if (this.props.params.imageOrientation === 3) {
      let imgStyleTmp = Object.assign({}, imgStyle1)
      imgStyleTmp['transform'] = 'rotate(180deg)'
      imgStyle1 = imgStyleTmp
    }

    console.log(this.props.params.imageOrientation);
    console.log(imgStyle1);

    let imgStyle2 = this.props.styles.img;
    if (this.props.params.image2Orientation === 6) {
      let imgStyleTmp = Object.assign({}, imgStyle2)
      imgStyleTmp['transform'] = 'rotate(90deg)'
      imgStyle2 = imgStyleTmp
    }
    else if (this.props.params.image2Orientation === 8) {
      let imgStyleTmp = Object.assign({}, imgStyle2)
      imgStyleTmp['transform'] = 'rotate(-90deg)'
      imgStyle2 = imgStyleTmp
    }
    else if (this.props.params.image2Orientation === 3) {
      let imgStyleTmp = Object.assign({}, imgStyle2)
      imgStyleTmp['transform'] = 'rotate(180deg)'
      imgStyle2 = imgStyleTmp
    }

    return (
      <Page size="A4" style={this.props.styles.page}>
        <Canvas 
        style={this.props.styles.canvas} 
        paint={painter => painter
                          .lineWidth(lineWithOut)
                          .roundedRect(14.5354, 31.2362, 283.4646, 340.1575, radius)
                          .roundedRect(14.5354, 371.3937, 283.4646, 283.4646, radius)
                          .roundedRect(14.5354, 654.8583, 283.4646, 56.6929, radius)
                          .roundedRect(298, 31.2362, 283.4646, 340.1575, radius)
                          .roundedRect(298, 371.3937, 283.4646, 283.4646, radius)
                          .roundedRect(298, 654.8583, 283.4646, 56.6929, radius)
                          .stroke(strokeColor)
                          .lineWidth(lineW)
                          .roundedRect(14.5354+5+lineW2, 31.2362+5+lineW2, 283.4646-10-lineW6, 340.1575-10-lineW6, radius)
                          .roundedRect(14.5354+5+lineW2, 371.3937+5.5+lineW2, 283.4646-10-lineW6, 283.4646-10-lineW6, radius)
                          .roundedRect(14.5354+5+lineW2, 654.8583+6.0+lineW2, 283.4646-10-lineW6, 56.6929-10-lineW6, radius)
                          .roundedRect(298+5.5+lineW2, 31.2362+5+lineW2, 283.4646-10-lineW6, 340.1575-10-lineW6, radius)
                          .roundedRect(298+5.5+lineW2, 371.3937+5.5+lineW2, 283.4646-10-lineW6, 283.4646-10-lineW6, radius)
                          .roundedRect(298+5.5+lineW2, 654.8583+6.0+lineW2, 283.4646-10-lineW6, 56.6929-10-lineW6, radius)
                          .fillAndStroke("white", this.props.params.selected2.color)
                        }
        />
        
        <View style={this.props.styles.name1ja} fixed>
          <Text render={() => (
          `${this.props.params.value4}`
          )} />
        </View>
        <View style={this.props.styles.name2ja} fixed>
          <Text render={() => (
          `${this.props.params.value4}`
          )} />
        </View>
        <View style={this.props.styles.name3ja} fixed>
          <Text render={() => (
          `${this.props.params.value3}`
          )} />
        </View>
        <View style={this.props.styles.name4ja} fixed>
          <Text render={() => (
          `${this.props.params.value3}`
          )} />
        </View>
        <View style={this.props.styles.image1} fixed>
          <Image src={this.props.params.image2} style={imgStyle2} />
        </View>
        <View style={this.props.styles.image2} fixed>
          <Image src={this.props.params.image2} style={imgStyle2} />
        </View>
        <View style={this.props.styles.image3} fixed>
          <Image src={this.props.params.image} style={imgStyle1} />
        </View>
        <View style={this.props.styles.image4} fixed>
          <Image src={this.props.params.image} style={imgStyle1} />
        </View>
      </Page>
    );
  }
}

MyPageJa.propTypes = {
  params: PropTypes.shape({
    value: PropTypes.string, 
    value2: PropTypes.string, 
    value3: PropTypes.string, 
    value4: PropTypes.string,
    image: PropTypes.string,
    image2: PropTypes.string,
    isRectangle: PropTypes.objectOf(PropTypes.string),
    language: PropTypes.objectOf(PropTypes.string),
    selected: PropTypes.objectOf(PropTypes.string),
    selected2: PropTypes.objectOf(PropTypes.string),
    selectedFont: PropTypes.objectOf(PropTypes.string)
  }),
  styles: PropTypes.any,
};

export default MyPageJa;
