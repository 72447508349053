import React from 'react';
import PropTypes from "prop-types";
import { Page, Text, View, Image, Canvas } from '@react-pdf/renderer';

class MyPageEn extends React.Component {
  render() {
    let radius = 0;
    if (this.props.params.isRectangle.value === "true") {
      radius = 0;
    } else {
      radius = 10;
    } 

    let lineW = 10;
    if (this.props.params.isRectangle.value === "true") {
      lineW = 10;
    } else {
      lineW = 4;
    } 

    let lineW2 = 0;
    if (this.props.params.isRectangle.value === "true") {
      lineW2 = 0;
    } else {
      lineW2 = 2;
    } 

    let lineW6 = 0;
    if (this.props.params.isRectangle.value === "true") {
      lineW6 = 0;
    } else {
      lineW6 = 6;
    } 

    let lineWithOut = 3;
    if (this.props.params.isRectangle.value === "true") {
      lineWithOut = 3;
    } else {
      lineWithOut = 1;
    } 

    let strokeColor = "white";
    if (this.props.params.isRectangle.value === "true") {
      strokeColor = "white";
    } else {
      strokeColor = "black";
    } 
    if (this.props.params.selected.color === "#FFFFFF"){
      strokeColor = "black";
    }

    let styleText1;
    if (this.props.params.selectedFont.value === "LearningCurve"){
      styleText1 = this.props.styles.name1font3
    }
    else {
      if (this.props.params.selectedFont.value === "AndikaNewBasic"){
      styleText1 = this.props.styles.name1font2
      }
      else {
        styleText1 = this.props.styles.name1
      }
    }

    let styleText2;
    if (this.props.params.selectedFont.value === "LearningCurve"){
      styleText2 = this.props.styles.name2font3
    }
    else {
      if (this.props.params.selectedFont.value === "AndikaNewBasic"){
      styleText2 = this.props.styles.name2font2
      }
      else {
        styleText2 = this.props.styles.name2
      }
    }

    let styleText3;
    if (this.props.params.selectedFont.value === "LearningCurve"){
      styleText3 = this.props.styles.name3font3
    }
    else {
      if (this.props.params.selectedFont.value === "AndikaNewBasic"){
      styleText3 = this.props.styles.name3font2
      }
      else {
        styleText3 = this.props.styles.name3
      }
    }

    let styleText4;
    if (this.props.params.selectedFont.value === "LearningCurve"){
      styleText4 = this.props.styles.name4font3
    }
    else {
      if (this.props.params.selectedFont.value === "AndikaNewBasic"){
      styleText4 = this.props.styles.name4font2
      }
      else {
        styleText4 = this.props.styles.name4
      }
    }

    let imgStyle1 = this.props.styles.img;
    if (this.props.params.imageOrientation === 6) {
      let imgStyleTmp = Object.assign({}, imgStyle1)
      imgStyleTmp['transform'] = 'rotate(90deg)'
      imgStyle1 = imgStyleTmp
    }
    else if (this.props.params.imageOrientation === 8) {
      let imgStyleTmp = Object.assign({}, imgStyle1)
      imgStyleTmp['transform'] = 'rotate(-90deg)'
      imgStyle1 = imgStyleTmp
    }
    else if (this.props.params.imageOrientation === 3) {
      let imgStyleTmp = Object.assign({}, imgStyle1)
      imgStyleTmp['transform'] = 'rotate(180deg)'
      imgStyle1 = imgStyleTmp
    }

    let imgStyle2 = this.props.styles.img;
    if (this.props.params.image2Orientation === 6) {
      let imgStyleTmp = Object.assign({}, imgStyle2)
      imgStyleTmp['transform'] = 'rotate(90deg)'
      imgStyle2 = imgStyleTmp
    }
    else if (this.props.params.image2Orientation === 8) {
      let imgStyleTmp = Object.assign({}, imgStyle2)
      imgStyleTmp['transform'] = 'rotate(-90deg)'
      imgStyle2 = imgStyleTmp
    }
    else if (this.props.params.image2Orientation === 3) {
      let imgStyleTmp = Object.assign({}, imgStyle2)
      imgStyleTmp['transform'] = 'rotate(180deg)'
      imgStyle2 = imgStyleTmp
    }

    return (
      <Page size="A4" style={this.props.styles.page}>
        <Canvas 
        style={this.props.styles.canvas} 
        paint={painter => painter
                          .lineWidth(lineWithOut)
                          .roundedRect(14.5354, 31.2362, 283.4646, 340.1575, radius)
                          .roundedRect(14.5354, 371.3937, 283.4646, 283.4646, radius)
                          .roundedRect(14.5354, 654.8583, 283.4646, 56.6929, radius)
                          .roundedRect(298, 31.2362, 283.4646, 340.1575, radius)
                          .roundedRect(298, 371.3937, 283.4646, 283.4646, radius)
                          .roundedRect(298, 654.8583, 283.4646, 56.6929, radius)
                          .stroke(strokeColor)
                          .lineWidth(lineW)
                          .roundedRect(14.5354+5+lineW2, 31.2362+5+lineW2, 283.4646-10-lineW6, 340.1575-10-lineW6, radius)
                          .roundedRect(14.5354+5+lineW2, 371.3937+5.5+lineW2, 283.4646-10-lineW6, 283.4646-10-lineW6, radius)
                          .roundedRect(14.5354+5+lineW2, 654.8583+6.0+lineW2, 283.4646-10-lineW6, 56.6929-10-lineW6, radius)
                          .roundedRect(298+5.5+lineW2, 31.2362+5+lineW2, 283.4646-10-lineW6, 340.1575-10-lineW6, radius)
                          .roundedRect(298+5.5+lineW2, 371.3937+5.5+lineW2, 283.4646-10-lineW6, 283.4646-10-lineW6, radius)
                          .roundedRect(298+5.5+lineW2, 654.8583+6.0+lineW2, 283.4646-10-lineW6, 56.6929-10-lineW6, radius)
                          .fillAndStroke("white", this.props.params.selected.color)
                        }
        />
        
        <View style={styleText1} fixed>
          <Text render={() => (
          `${this.props.params.value}`
          )} />
        </View>
        <View style={styleText2} fixed>
          <Text render={() => (
          `${this.props.params.value}`
          )} />
        </View>
        <View style={styleText3} fixed>
          <Text render={() => (
          `${this.props.params.value2}`
          )} />
        </View>
        <View style={styleText4} fixed>
          <Text render={() => (
          `${this.props.params.value2}`
          )} />
        </View>
        <View style={this.props.styles.image1} fixed>
          <Image src={this.props.params.image} style={imgStyle1} />
        </View>
        <View style={this.props.styles.image2} fixed>
          <Image src={this.props.params.image} style={imgStyle1} />
        </View>
        <View style={this.props.styles.image3} fixed>
          <Image src={this.props.params.image2} style={imgStyle2} />
        </View>
        <View style={this.props.styles.image4} fixed>
          <Image src={this.props.params.image2} style={imgStyle2} />
        </View>
      </Page>
    );
  }
}

MyPageEn.propTypes = {
  params: PropTypes.shape({
    value: PropTypes.string, 
    value2: PropTypes.string, 
    value3: PropTypes.string, 
    value4: PropTypes.string,
    image: PropTypes.string,
    image2: PropTypes.string,
    isRectangle: PropTypes.objectOf(PropTypes.string),
    language: PropTypes.objectOf(PropTypes.string),
    selected: PropTypes.objectOf(PropTypes.string),
    selected2: PropTypes.objectOf(PropTypes.string),
    selectedFont: PropTypes.objectOf(PropTypes.string)
  }),
  styles: PropTypes.any,
};

export default MyPageEn;
