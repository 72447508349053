import React from "react";
import { Link } from "gatsby";
import { Document, Font, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import SEO from "../components/seo";

import chroma from 'chroma-js';
import Klee from '../fonts/KleeOne-RegularLite.ttf'
import AndikaNewBasic from '../fonts/AndikaNewBasic-Regular.ttf'
import Montessoriscript from '../fonts/montessoriscript.ttf'
import LearningCurve from '../fonts/learning_curve_regular_ot_tt.ttf'

import Select from 'react-select';

import Header from "../components/header";
import Footer from "../components/footer";
import Buymeacoffee from "../components/buymeacoffee";

import MyPageEn from "../components/mypageen";
import MyPageJa from "../components/mypageja";

import FormInputText from "../components/forminputtext";

import ogpImage from "../images/cce_ogimg.png";
import holderImage from '../images/holder.png'

import loadImage from "blueimp-load-image"

const colourOptions = [
  { value: 'white', label: 'White', color: '#FFFFFF' },
  { value: 'black', label: 'Black', color: '#080000' },
  { value: 'blue', label: 'Blue', color: '#005192'},
  { value: 'green', label: 'Green', color: '#1d7a21' },
  { value: 'yellow', label: 'Yellow', color: '#ffdf00' },
  { value: 'orange', label: 'Orange', color: '#ee7701' },
  { value: 'red', label: 'Red', color: '#ea5415' },
  { value: 'brown', label: 'Brown', color: '#88593b' },
  { value: 'gray', label: 'Gray', color: '#737061' },
];

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : (data.color === '#FFFFFF') ?
        'black' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};


Font.register({
  family: 'Klee',
  src: Klee
});

Font.register({
  family: 'Montessoriscript',
  src: Montessoriscript
});

Font.register({
  family: 'AndikaNewBasic',
  src: AndikaNewBasic
});

Font.register({
  family: 'LearningCurve',
  src: LearningCurve
});

// Create styles
const styles = StyleSheet.create({
  title: {
    margin: 20,
    fontSize: 25,
    textAlign: 'center',
    backgroundColor: '#e4e4e4',
    textTransform: 'uppercase',
  },
  body: {
    flexGrow: 1,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  block: {
    flexGrow: 1,
  },
  text: {
    width: '60%',
    margin: 10,
    // fontFamily: 'NotoSerifJP-Regular',
    textAlign: 'justify',
  },
  textItalic: {
    width: '60%',
    margin: 10,
    fontStyle: 'italic',
    textAlign: 'justify',
  },
  fill1: {
    width: '40%',
    backgroundColor: '#e14427',
  },
  fill2: {
    flexGrow: 2,
    backgroundColor: '#e6672d',
  },
  fill3: {
    flexGrow: 2,
    backgroundColor: '#e78632',
  },
  fill4: {
    flexGrow: 2,
    backgroundColor: '#e29e37',
  },
  canvas: {
    width: 600,
    height: 800
  },
  name1: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Montessoriscript'
  },
  name1font2: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557-10,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'AndikaNewBasic'
  },
  name1font3: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557+3,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'LearningCurve'
  },
  name2: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Montessoriscript'
  },
  name2font2: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5-10,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'AndikaNewBasic'
  },
  name2font3: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5+3,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'LearningCurve'
  },
  name3: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Montessoriscript'
  },
  name3font2: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557-10,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'AndikaNewBasic'
  },
  name3font3: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557+3,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'LearningCurve'
  },
  name4: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Montessoriscript'
  },
  name4font2: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5-10,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'AndikaNewBasic'
  },
  name4font3: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5+3,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'LearningCurve'
  },
  name1ja: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557-3-2,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Klee'
  },
  name2ja: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5-3-2,
    left: 14.5354,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Klee'
  },
  name3ja: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 331.6557-3-2,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Klee'
  },
  name4ja: {
    position: 'absolute',
    fontSize: 24,
    width: 283.4646,
    top: 671.8132+0.5-3-2,
    left: 298+0.5,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Klee'
  },
  img: {
    width: 255.1181-10,
    height: 255.1181-10,
    objectFit: 'cover',
    transform: 'rotate(0deg)',
  },
  image1: {
    position: 'absolute',
    fontSize: 24,
    width: 255.1181-10,
    height: 255.1181-10,
    top: 45.4094+5,
    left: 28.7087+5,
    right: 0,
    textAlign: 'center',
  },
  image2: {
    position: 'absolute',
    fontSize: 24,
    width: 255.1181-10,
    height: 255.1181-10,
    top: 385.5669+5.5,
    left: 28.7087+5,
    right: 0,
    textAlign: 'center',
  },
  image3: {
    position: 'absolute',
    fontSize: 24,
    width: 255.1181-10,
    height: 255.1181-10,
    top: 45.4094+5,
    left: 312.1732+5,
    right: 0,
    textAlign: 'center',
  },
  image4: {
    position: 'absolute',
    fontSize: 24,
    width: 255.1181-10,
    height: 255.1181-10,
    top: 385.5669+5.5,
    left: 312.1732+5.5,
    right: 0,
    textAlign: 'center',
  },
  paper: {
    padding: 20,
  },
  inputFile: {
    display: 'none',
  },
  toolArea: {
    marginTop: 50,
  },
  selectItem: {
    fontFamily: 'sans-serif',
  }
});

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '', 
      value2: '', 
      value3: '', 
      value4: '',
      text1: '', 
      text2: '', 
      text3: '', 
      text4: '',
      image: holderImage,
      image2: holderImage,
      imageOrientation: 1,
      image2Orientation: 1,
      isRectangle: {value: "true", label: 'Rectangle'},
      language: {value: "enja", label: 'English and Japanese'},
      selected: {value: 'white', label: 'White', color: '#FFFFFF'},
      selected2: {value: 'white', label: 'White', color: '#FFFFFF'},
      selectedFont: {value: "Montessoriscript", label: 'Montessoriscript'},
      isClient: false,
    };

    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleChangeColor2 = this.handleChangeColor2.bind(this);
    this.handleChangeFont = this.handleChangeFont.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onImageChange2 = this.onImageChange2.bind(this);
    this.handleChangeFrame = this.handleChangeFrame.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleUpdatePDF = this.handleUpdatePDF.bind(this);
    this.handleUpdatePDFByEnter = this.handleUpdatePDFByEnter.bind(this);
    this.handleClickUploadFile = this.handleClickUploadFile.bind(this);
    this.handleClickUploadFile2 = this.handleClickUploadFile2.bind(this);
    this.handleLoadedIframe = this.handleLoadedIframe.bind(this);
  }

  componentDidMount() {
    this.setState({
      isClient: true,
    });
  }

  handleChangeFrame(selectedOption) {
    this.setState({isRectangle: selectedOption});
  }

  handleChangeLanguage(selectedOption) {
    this.setState({language: selectedOption});
  }

  handleChangeColor(selectedOption) {
    this.setState({selected: selectedOption});
  }

  handleChangeColor2(selectedOption) {
    this.setState({selected2: selectedOption});
  }

  handleChangeFont(selectedOption) {
    this.setState({selectedFont: selectedOption});
  }

  handleUpdatePDF(event) {
    event.preventDefault();
    if (document.getElementById('text1')) { const text = document.getElementById('text1').value; this.setState({value: text}); this.setState({text1: text}); }
    if (document.getElementById('text2')) { const text = document.getElementById('text2').value; this.setState({value2: text}); this.setState({text2: text}); }
    if (document.getElementById('text3')) { const text = document.getElementById('text3').value; this.setState({value3: text}); this.setState({text3: text}); }
    if (document.getElementById('text4')) { const text = document.getElementById('text4').value; this.setState({value4: text}); this.setState({text4: text}); }
  }

  handleUpdatePDFByEnter(event) {
    if (event.key == 'Enter') { 
      event.preventDefault()
      if (document.getElementById('text1')) { const text = document.getElementById('text1').value; this.setState({value: text}); this.setState({text1: text}); }
      if (document.getElementById('text2')) { const text = document.getElementById('text2').value; this.setState({value2: text}); this.setState({text2: text}); }
      if (document.getElementById('text3')) { const text = document.getElementById('text3').value; this.setState({value3: text}); this.setState({text3: text}); }
      if (document.getElementById('text4')) { const text = document.getElementById('text4').value; this.setState({value4: text}); this.setState({text4: text}); }
    }    
  }

  handleClickUploadFile(event) {
    event.preventDefault();
    document.getElementById('buttonfile1').click();
  }

  handleClickUploadFile2(event) {
    event.preventDefault();
    document.getElementById('buttonfile2').click();
  } 

  onImageChange = event => {
    // 4MB
    if(event.target.files[0].size > 4194304) {
       alert("File size is too big! (Max: 4MB)");
       event.preventDefault()
       return;
    }

    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      loadImage.parseMetaData(img, (data) => {
          let orientation = 1;
          if (data.exif) {
            orientation = data.exif.get('Orientation')
          }

          this.setState({
            image: URL.createObjectURL(img),
            imageOrientation: orientation
          })
      })     
    }
  }

  onImageChange2 = event => {
    // 4MB
    if(event.target.files[0].size > 4194304) {
       alert("File size is too big! (Max: 4MB)");
       event.preventDefault()
       return;
    }

    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      loadImage.parseMetaData(img, (data) => {
          let orientation = 1;
          if (data.exif) {
            orientation = data.exif.get('Orientation')
          }

          this.setState({
            image2: URL.createObjectURL(img),
            image2Orientation: orientation
          })
      })
    }
  } 

  handleLoadedIframe(event) {
    //hack ios safari
    if (event.target.contentWindow.document.querySelector("img") != null) {
      event.target.contentWindow.document.querySelector("img").style.width = "100%";
    }
  } 

  render() {

    const downloadIcon = <svg className="inline mr-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                          </svg>;

    let inputText1;
    let inputText2;
    let enFrameColor;
    let jaFrameColor;
    let outputButton;
    let preview;
    if (this.state.language.value === "enja") {
      let arrayTmp = [];
      arrayTmp.push(<FormInputText value={this.state.text1} textid="text1" key="text1" texttitle="Name1" onKeyEnter={this.handleUpdatePDFByEnter} />);
      arrayTmp.push(<FormInputText value={this.state.text3} textid="text3" key="text3" texttitle="なまえ1" onKeyEnter={this.handleUpdatePDFByEnter} />);
      inputText1 = arrayTmp;

      let arrayTmp2 = [];
      arrayTmp2.push(<FormInputText value={this.state.text2} textid="text2" key="text2" texttitle="Name2" onKeyEnter={this.handleUpdatePDFByEnter} />);
      arrayTmp2.push(<FormInputText value={this.state.text4} textid="text4" key="text4" texttitle="なまえ2" onKeyEnter={this.handleUpdatePDFByEnter} />);
      inputText2 = arrayTmp2;
      
      enFrameColor = <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="changeColor" className="block text-sm font-medium text-gray-700">Frame color (English)</label>
                      <Select value={this.state.selected}
                      onChange={this.handleChangeColor}
                      defaultValue={colourOptions[2]}
                      label="Single select"
                      id="changeColor"
                      name="changeColor"
                      isSearchable={false}
                      options={colourOptions}
                      styles={colourStyles}
                      />
                    </div>;

      jaFrameColor = <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="changeColor2" className="block text-sm font-medium text-gray-700">Frame color (Japanese)</label>
                      <Select value={this.state.selected2}
                      onChange={this.handleChangeColor2}
                      defaultValue={colourOptions[2]}
                      label="Single select"
                      id="changeColor2"
                      name="changeColor2"
                      isSearchable={false}
                      options={colourOptions}
                      styles={colourStyles}
                      />
                    </div>;

      outputButton = <button 
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={async () => {
                    const state = await this.state;
                    const doc = <Document><MyPageEn params={state} styles={styles} /><MyPageJa params={state} styles={styles} /></Document>;
                    const asPdf = pdf([]);
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    saveAs(blob, 'document.pdf');
                  }}
                  >
                  {downloadIcon} Download PDF</button>;

      let arrayTmp3 = [];
      arrayTmp3.push(<PDFViewer width="280" height="396" className="pdfpreviewer inline-block" key="pdfpreview1" onLoad={this.handleLoadedIframe}><Document><MyPageEn params={this.state} styles={styles} /></Document></PDFViewer>);
      arrayTmp3.push(<PDFViewer width="280" height="396" className="pdfpreviewer inline-block" key="pdfpreview2" onLoad={this.handleLoadedIframe}><Document><MyPageJa params={this.state} styles={styles} /></Document></PDFViewer>);
      preview = arrayTmp3;
    } else if(this.state.language.value === "en") {
      inputText1 = <FormInputText value={this.state.text1} textid="text1" key="text1" texttitle="Name1" onKeyEnter={this.handleUpdatePDFByEnter} />;
      inputText2 = <FormInputText value={this.state.text2} textid="text2" key="text2" texttitle="Name2" onKeyEnter={this.handleUpdatePDFByEnter} />;
      enFrameColor = <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="changeColor" className="block text-sm font-medium text-gray-700">Frame color (English)</label>
                      <Select value={this.state.selected}
                      onChange={this.handleChangeColor}
                      defaultValue={colourOptions[2]}
                      label="Single select"
                      id="changeColor"
                      name="changeColor"
                      isSearchable={false}
                      options={colourOptions}
                      styles={colourStyles}
                      />
                    </div>;

      jaFrameColor = null;
      outputButton = <button 
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={async () => {
                    const state = await this.state;
                    const doc = <Document><MyPageEn params={state} styles={styles} /></Document>;
                    const asPdf = pdf([]);
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    saveAs(blob, 'document.pdf');
                  }}
                  >
                  {downloadIcon} Download PDF</button>;
      preview = <PDFViewer
              width="280"
              height="396"
              className="pdfpreviewer inline-block"
              onLoad={this.handleLoadedIframe}>
              <Document>
                <MyPageEn params={this.state} styles={styles} />
              </Document>
            </PDFViewer>;
    } else if(this.state.language.value === "ja") {
      inputText1 = <FormInputText value={this.state.text3} textid="text3" key="text3" texttitle="なまえ1" onKeyEnter={this.handleUpdatePDFByEnter} />;
      inputText2 = <FormInputText value={this.state.text4} textid="text4" key="text4" texttitle="なまえ2" onKeyEnter={this.handleUpdatePDFByEnter} />;
      enFrameColor = null;
      jaFrameColor = <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="changeColor2" className="block text-sm font-medium text-gray-700">Frame color (Japanese)</label>
                      <Select value={this.state.selected2}
                      onChange={this.handleChangeColor2}
                      defaultValue={colourOptions[2]}
                      label="Single select"
                      id="changeColor2"
                      name="changeColor2"
                      isSearchable={false}
                      options={colourOptions}
                      styles={colourStyles}
                      />
                    </div>;
      outputButton = <button 
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={async () => {
                    const state = await this.state;
                    const doc = <Document><MyPageJa params={state} styles={styles} /></Document>;
                    const asPdf = pdf([]);
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    saveAs(blob, 'document.pdf');
                  }}
                  >
                  {downloadIcon} Download PDF</button>;
      preview = <PDFViewer
              width="280"
              height="396"
              className="pdfpreviewer inline-block"
              onLoad={this.handleLoadedIframe}>
              <Document>
                <MyPageJa params={this.state} styles={styles} />
              </Document>
            </PDFViewer>;
    }

    let enFont;
    
    if(this.state.language.value === "ja") {
      enFont = null;
    }
    else {
      enFont = <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label htmlFor="selectedFont" className="block text-sm font-medium text-gray-700">English Fonts</label>
                  <Select 
                  value={this.state.selectedFont}
                  name="selectedFont" 
                  id="selectedFont"
                  onChange={this.handleChangeFont}
                  label="Single select"
                  isSearchable={false}
                  options={[{ value: "Montessoriscript", label: 'Montessoriscript' },
                          { value: "AndikaNewBasic", label: 'AndikaNewBasic' },
                          { value: "LearningCurve", label: 'LearningCurve' }]}
                  />
                </div>;
    }

    return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <SEO
        keywords={[`Montessori`, `montessori`, `Montessori Material`]}
        title="Classified Cards Editor"
        description="Classified Cards Editor enables users to create their own Montessori Classified Cards"
        image={ogpImage}
      />
      <Header />

      <main className="flex-grow container px-7 lg:px-20 xl:px-20 xl:px-20 py-6">
        <div className="flex flex-wrap -mx-3 lg:-mx-6 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 gap-4">
          <h1 className="text-2xl font-extrabold text-gray-900 tracking-tight -mb-2 col-span-2">
          Classified Cards Editor
          <Link to="/cce-inst">
          <svg className="h-6 w-6 inline ml-5 align-text-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>
          </Link>
          </h1>
          <div className="col-span-2 lg:col-span-1">
            <form>
              <div className="shadow rounded-lg">
                <div className="px-4 py-5 bg-white sm:p-6 rounded-t-lg">
                  <div className="grid grid-cols-3 gap-6">

                    <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="changeFrame" className="block text-sm font-medium text-gray-700">
                      Language</label>
                      <Select 
                      value={this.state.language}
                      onChange={this.handleChangeLanguage}
                      label="Single select"
                      id="changeLang"
                      name="changeLang" 
                      isSearchable={false}
                      options={[{ value: "enja", label: 'English and Japanese' },
                              { value: "en", label: 'English' },
                              { value: "ja", label: 'Japanese' },]}
                      />
                    </div>

                    <div className="col-span-3 sm:col-span-3 lg:col-span-3 grid grid-cols-3 gap-6">
                      {inputText1}

                      <div className="col-span-1 sm:col-span-1 lg:col-span-1 self-end">
                        <input
                          style={styles.inputFile}
                          id="buttonfile1"
                          type="file"
                          accept="image/jpeg,image/png" 
                          name="myImage" 
                          onChange={this.onImageChange}
                        />
                        <button 
                        type="button" 
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={this.handleClickUploadFile}
                        >
                        <svg className="inline" width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg> Set Image1
                        </button>
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-3 lg:col-span-3 grid grid-cols-3 gap-6">
                      {inputText2}

                      <div className="col-span-1 sm:col-span-1 lg:col-span-1 self-end">
                        <input
                          style={styles.inputFile}
                          id="buttonfile2"
                          type="file"
                          accept="image/jpeg,image/png" 
                          name="myImage2" 
                          onChange={this.onImageChange2}
                        />
                        <button 
                        type="button" 
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={this.handleClickUploadFile2}
                        >
                        <svg className="inline" width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg> Set Image2
                        </button>
                      </div>
                    </div>                    

                    <div className="col-span-3 sm:col-span-3 lg:col-span-3 justify-self-end">
                      <button 
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        onClick={this.handleUpdatePDF}
                        >
                        <svg className="inline mr-1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg> Update PDF
                      </button>
                    </div>

                    {enFont}

                    <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                      <label htmlFor="changeFrame" className="block text-sm font-medium text-gray-700">Style</label>
                      <Select value={this.state.isRectangle}
                      onChange={this.handleChangeFrame}
                      label="Single select"
                      id="changeFrame"
                      name="changeFrame"
                      isSearchable={false}
                      options={[{ value: "true", label: 'Rectangle' },
                              { value: "false", label: 'Rounded Rectangle' },]}
                      />
                    </div>

                    {enFrameColor}

                    {jaFrameColor}

                    
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 rounded-b-lg">
                {outputButton}
                </div>
              </div>
            </form>
            <ul className="mt-3 ml-6 list-decimal">
              <li key={1} className="italic">Note: We do not collect your data (text/image) that filled out the form. The data is processed on only your computer.</li>
              <li key={2}><a className="underline"  href="https://montessoridigital.org/node/7513" target='_blank' rel="noreferrer">How to Use Classified Cards <svg className="inline" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg></a></li>
            </ul>
          </div>
          <div className="">
          {this.state.isClient && preview}
          </div>
        </div>
      </main>
      <Buymeacoffee />
      <Footer />
    </div>
    );
  }
}


const App = () => (
    <NameForm /> 
);

export default App
