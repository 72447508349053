import React from "react";
import PropTypes from "prop-types";

class FormInputText extends React.Component {
	render() {
		return (
		<div className="col-span-1 sm:col-span-1 lg:col-span-1">
		<label 
		htmlFor={this.props.textid} 
		className="block text-sm font-medium text-gray-700">
		{this.props.texttitle}
		</label>
		<input 
		onKeyPress={this.props.onKeyEnter}
		defaultValue={this.props.value}
		type="text"
		id={this.props.textid}
		name={this.props.textid} 
		className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
		</div>
		);
	}
}

FormInputText.propTypes = {
  textid: PropTypes.string,
  texttitle: PropTypes.string,
  value: PropTypes.string,
  onKeyEnter: PropTypes.func,
};

export default FormInputText